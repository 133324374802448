.App_carousel__2tHDE {
    width: 100%;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    min-height: 500px;
}
.App_slidingChild__TByaL {
    white-space: nowrap;
    height: 100%;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: -webkit-transform 1s ease-in-out 0.5s;
    transition: -webkit-transform 1s ease-in-out 0.5s;
    transition: transform 1s ease-in-out 0.5s;
    transition: transform 1s ease-in-out 0.5s, -webkit-transform 1s ease-in-out 0.5s;
    will-change: transform;
}
.App_carousel__2tHDE[data-transitioning='true'] {
    -webkit-animation: App_shrinkAndBack__25b6G 1.8s ease-in-out forwards;
            animation: App_shrinkAndBack__25b6G 1.8s ease-in-out forwards;
}
@-webkit-keyframes App_shrinkAndBack__25b6G {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    20% {
        -webkit-transform: scale(0.98);
                transform: scale(0.98);
    }
    80% {
        -webkit-transform: scale(0.98);
                transform: scale(0.98);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@keyframes App_shrinkAndBack__25b6G {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    20% {
        -webkit-transform: scale(0.98);
                transform: scale(0.98);
    }
    80% {
        -webkit-transform: scale(0.98);
                transform: scale(0.98);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.Sheet_page__9zs4k {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #383448;
    margin: 0 1rem;
    vertical-align: top;
}

.Sheet_page__9zs4k:first-child {
    margin-left: 0;
}

.Sheet_page__9zs4k:last-of-type {
    margin-right: 0;
}

.Home_grid__38YvX {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 100%;
}
.Home_layer__1xRWW {
    grid-area: 1 / 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-height: 100%;
    height: 100%;
}
.Home_nameSVG__WESpX {
    width: 100%;
    max-width: 1500px;
    min-width: 700px;
}
.Home_animatedText__3fzrD {
    fill: white;
    font-size: 24pt;
    font-weight: 100;
    opacity: 0;
    -webkit-animation: Home_appear__2SeCz 500ms ease-in forwards;
            animation: Home_appear__2SeCz 500ms ease-in forwards;
    -webkit-animation-delay: 400ms;
            animation-delay: 400ms;
}

@-webkit-keyframes Home_appear__2SeCz {
    100% {
        opacity: 1;
    }
}

@keyframes Home_appear__2SeCz {
    100% {
        opacity: 1;
    }
}

.Home_detailsEdge__KTx1A {
    text-align: center;
    color: #fff;
    font-size: 2rem;
}

.Home_detailsEdge__KTx1A > div a {
    display: inline-block;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    cursor: pointer;
}


.Home_details__iLeqT {
    margin-top: 9rem;
    text-align: center;
    color: #fff;
    font-size: 2rem;
    -webkit-animation: Home_moveaway__3_Vsv 0.8s ease-in-out 5.7s forwards;
            animation: Home_moveaway__3_Vsv 0.8s ease-in-out 5.7s forwards;
}
.Home_details__iLeqT > div {
}

.Home_details__iLeqT > div a {
    display: inline-block;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    cursor: pointer;
}

.Home_detailsText__32ueN {
    display: inline-block;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.Home_skewUp__10CPo,
.Home_skewLeft__JLOHT,
.Home_skewRight__2eiCR {
    text-decoration: none;
    color: #fff;
    display: inline-block;
}
.Home_skewUp__10CPo:hover {
    -webkit-transform: perspective(100px) rotateX(-10deg);
            transform: perspective(100px) rotateX(-10deg);
}
.Home_skewLeft__JLOHT:hover .Home_detailsText__32ueN {
    -webkit-transform: skew(10deg);
            transform: skew(10deg);
}
.Home_skewRight__2eiCR:hover .Home_detailsText__32ueN {
    -webkit-transform: skewX(-10deg);
            transform: skewX(-10deg);
}
@-webkit-keyframes Home_moveaway__3_Vsv {
    100% {
        margin-top: 12rem;
    }
}
@keyframes Home_moveaway__3_Vsv {
    100% {
        margin-top: 12rem;
    }
}
.Home_seperatorCircle__xwwIv {
    color: lightgreen;
    cursor: default;
}
.Home_navigationGrid__FtaY7 {
    display: grid;
    width: 100%;
}
.Home_unAnimatedText__2XLi_ {
    color: #fff;
    font-size: 5rem;
}
.Home_navigation__GOZms {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}
.Home_navigation__GOZms > div {
    margin: 0.5em
}
.Contact_paddingWrapper__2xQBU {
    white-space: normal;
    max-height: 100%;
    height: 100%;
}
.Contact_sideBySide__2p_61 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%;
}
@media (max-width: 500px) {
    .Contact_sideBySide__2p_61 {
        display: block;
        padding: 2em;
        padding-bottom: 5em;
    }
    .Contact_sideBySide__2p_61 h1 {
        font-size: 1em;
    }
}

.Contact_profilePicture__2QVbo img {
    border-radius: 50%;
    border-bottom-right-radius: 0;
    width: 500px;
    max-width: 100%;
    -webkit-filter: grayscale(100%) sepia(90%) hue-rotate(60deg) saturate(300%)
        contrast(0.7);
            filter: grayscale(100%) sepia(90%) hue-rotate(60deg) saturate(300%)
        contrast(0.7);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
.Contact_profilePicture__2QVbo img:hover {
    border-radius: 50%;
    border-bottom-right-radius: 0;
    width: 500px;
    max-width: 100%;
    -webkit-filter: none;
            filter: none;
}
.Contact_profilePicture__2QVbo {
    width: 30%;
    max-width: 30rem;
    min-width: 10rem;
}
.Contact_contactInfo__3lkNn {
    display: inline-block;
    font-size: 2.5rem;
    width: 30%;
    color: #fff;
    padding: 2%;
    min-width: 300px;
    text-align: left;
    vertical-align: middle;
}
.Contact_contactInfo__3lkNn span:not([role='img']) {
    display: block;
    font-size: 1.5rem;
}
.Contact_contactInfo__3lkNn p {
    margin: 0;
    grid-column-start: 2;
    font-weight: bold;
    margin-bottom: 15px;
    color: lightgreen;
}
.Contact_emailSvg__2cS5J {
    max-width: 80%;
    cursor: pointer;
}
.Contact_emailSvg__2cS5J g {
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
.Contact_emailSvg__2cS5J .Contact_checkmark__1YtNa {
    fill: lightgreen;
    -webkit-transform-origin: 10px -25px;
            transform-origin: 10px -25px;
}
.Contact_emailSvg__2cS5J[data-is-copying='0']:hover > g {
    -webkit-transform: translateY(-51px);
            transform: translateY(-51px);
}
.Contact_emailSvg__2cS5J[data-is-copying='1'] > g {
    -webkit-transform: translateY(52px);
            transform: translateY(52px);
}
.Contact_emailSvg__2cS5J[data-is-copying='1'] .Contact_checkmark__1YtNa {
    fill: red;
    -webkit-transform: rotate(0deg) scale(0);
            transform: rotate(0deg) scale(0);
    -webkit-animation: Contact_rotate__1PHdH 1s ease-in-out forwards;
            animation: Contact_rotate__1PHdH 1s ease-in-out forwards;
}
@-webkit-keyframes Contact_rotate__1PHdH {
    100% {
        -webkit-transform: rotate(360deg) scale(1);
                transform: rotate(360deg) scale(1);
        fill: lightgreen;
    }
}
@keyframes Contact_rotate__1PHdH {
    100% {
        -webkit-transform: rotate(360deg) scale(1);
                transform: rotate(360deg) scale(1);
        fill: lightgreen;
    }
}
.Contact_goHome__3ctKN {
    margin-top: 10%;
}

.generics_skewRight__3ts5y:hover {
    -webkit-transform: skewX(-10deg);
            transform: skewX(-10deg);
}

.sideBySide_paddingWrapper__1TT9U {
    padding: 1em;
    white-space: normal;
    height: calc(100% - 15em);
}
.sideBySide_sideBySide__2lPLB {
    height: 100%;
    display: -webkit-flex;
    display: flex;
}
.sideBySide_sideBySide__2lPLB > div {
    padding: 0.5em;
}
.sideBySide_sideBySide__2lPLB > div:first-child {
    border-right: 1px solid #90ee9033;
}
@media (max-width: 500px) {
    .sideBySide_sideBySide__2lPLB > div {
        padding: 0;
    }
}
.sideBySide_sideBySide__2lPLB :nth-child(2) {
    -webkit-flex: 1 1;
            flex: 1 1;
}
@media (max-width: 500px) {
    .sideBySide_sideBySide__2lPLB {
        display: block;
        padding: 2em;
        padding-bottom: 5em;
    }
    .sideBySide_sideBySide__2lPLB h1 {
        font-size: 1em;
    }
}
.sideBySide_title__3kPqn {
    font-size: 1.5rem;
    color: #fff;
}
.sideBySide_paddedList__1dDZn {
    padding: 1%;
}
.sideBySide_theRightSideList__3CTKP {
    font-size: 1.5rem;
    line-height: 2rem;
    color: #fff;
    overflow-y: scroll;
    max-height: 100%;
    padding-right: 15px;
}

.sideBySide_theRightSideList__3CTKP::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #333;
}

.sideBySide_theRightSideList__3CTKP::-webkit-scrollbar {
    width: 12px;
    background-color: #333;
    border-radius: 10px;
}

.sideBySide_theRightSideList__3CTKP::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #333;
}

.sideBySide_theRightSideList__3CTKP:hover::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #333;
}

.sideBySide_theRightSideList__3CTKP:hover::-webkit-scrollbar {
    width: 12px;
    background-color: #333;
    border-radius: 10px;
    visibility: visible;
}

.sideBySide_theRightSideList__3CTKP:hover::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #605a77;
    visibility: visible;
}

.sideBySide_theRightSideList__3CTKP:hover {
    max-width: 100%;
    width: 100%;
}
@media (max-width: 500px) {
    .sideBySide_theRightSideList__3CTKP {
        overflow-y: scroll;
        max-height: 100%;
        width: 100%;
    }
}
.sideBySide_theRightSideList__3CTKP a {
    color: lightgreen;
}
.sideBySide_theRightSideList__3CTKP li {
    margin: 20px;
}
.sideBySide_badge__1XEMj {
    display: inline-block;
    padding: 0 0.3em;
    background: lightgreen;
    color: #333;
    font-size: 0.5em;
    height: 24px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-radius: 3px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 2em;
}
.sideBySide_justGreen__3LRIB {
    color: lightgreen;
}
.sideBySide_websitesIbuilt__3IGq1 {
    margin-top: 2%;
}

.About_paddingWrapper__20nX2 {
    white-space: normal;
    max-height: 100%;
    height: 100%;
}
.About_sideBySide__12evH {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%;
}
.About_profilePicture__1zaLe img {
    border-radius: 50%;
    border-bottom-right-radius: 0;
    width: 500px;
    max-width: 100%;
    -webkit-filter: grayscale(100%) sepia(90%) hue-rotate(60deg) saturate(300%)
        contrast(0.7);
            filter: grayscale(100%) sepia(90%) hue-rotate(60deg) saturate(300%)
        contrast(0.7);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
.About_profilePicture__1zaLe img:hover {
    border-radius: 50%;
    border-bottom-right-radius: 0;
    width: 500px;
    max-width: 100%;
    -webkit-filter: none;
            filter: none;
}
.About_profilePicture__1zaLe {
    width: 30%;
    max-width: 30rem;
    min-width: 10rem;
}
.About_aboutMe__kz9u2 {
    display: inline-block;
    font-size: 2.5rem;
    width: 30%;
    color: #fff;
    padding: 2%;
    min-width: 300px;
    text-align: left;
    vertical-align: middle;
}
.About_aboutMe__kz9u2 span:not([role='img']) {
    display: block;
    font-size: 1.5rem;
}
.About_aboutMe__kz9u2 p {
    margin: 0;
    font-size: 1.34rem;
    line-height: 1.4em;
}
.About_emailSvg__1TU5v {
    max-width: 90%;
    cursor: pointer;
}

@media (max-width: 500px) {
    .About_sideBySide__12evH {
        display: block;
        padding: 2em;
        padding-bottom: 5em;
    }
    .About_sideBySide__12evH h1 {
        font-size: 1em;
    }
    .About_aboutMe__kz9u2 p {
        margin: 0;
        font-size: 1rem;
        line-height: 1.2em;
        max-width: 80%
    }
}

.NavButtons_navButtonsWrapper__2Ewe1 {
    position: absolute;
    bottom: 5em;
    left: 2em;
    height: 20px;
}
@media (max-width: 500px) {
    .NavButtons_navButtonsWrapper__2Ewe1 {
        bottom: 0em;
    }
}
.NavButtons_navButtonsWrapper__2Ewe1 h3 {
    margin: 0;
    margin-bottom: -10px;
    color: #eee;
}
.NavButtons_navButtonsWrapper__2Ewe1 a {
    font-size: calc(32px + (34 - 32) * (100vw - 400px) / (800 - 400));
    color: #fff;
    text-decoration: none;
    -webkit-transition: color 0.5s ease-in-out;
    transition: color 0.5s ease-in-out;
}
.NavButtons_navButtonsWrapper__2Ewe1 span:hover {
    color: lightcoral;
}
.NavButtons_navButtonsWrapper__2Ewe1 a[data-current='true'] {
    color: lightgreen;
    pointer-events: none;
}
.NavButtons_navArrow__29xTK {
    display: inline-block;
    color: #fff;
    vertical-align: middle;
    font-size: 2em;
    line-height: 1em;
    padding: 0.4em 0.2em;
    padding-top: 0;
}

.NotFound_wrapper__3z_1w {
    display: grid;
    height: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    justify-items: center;
}
.NotFound_wrapper__3z_1w > * {
    grid-area: 1 / 1;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
}
.NotFound_wrapper__3z_1w > canvas {
    z-index: 0;
}

.NotFound_wrapper__3z_1w > div {
    z-index: 3;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;

}

.NotFound_wrapper__3z_1w > h1 {
    color: #fff;
    font-size: 3rem;
    padding: 10px;
    background: #000;
    margin: 0!important;
    margin-bottom: -5em;
    line-height: 1em;
    padding-top: 0.33em;
    vertical-align: middle;
    -webkit-transform: skew(-15deg);
            transform: skew(-15deg);
}
html,
body,
#root {
    height: 100%;
    margin: 0;
    font-family: 'Josefin Slab', serif;
}
@media (max-width: 1200px) {
    html {
        font-size: 0.8em;
    }
}
@media (max-width: 720px) {
    html {
        font-size: 0.5em;
    }
}
@media (max-width: 300px) {
    html {
        font-size: 0.3em;
    }
}
a {
    text-decoration: none;
    color: #fff;
}
a:hover {
    text-decoration: none;
    color: lightgreen;
}
p {
    margin: 0;
}
h1, h2, h3, h4 {
    margin-top: 2%
}
