.paddingWrapper {
    white-space: normal;
    max-height: 100%;
    height: 100%;
}
.sideBySide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.profilePicture img {
    border-radius: 50%;
    border-bottom-right-radius: 0;
    width: 500px;
    max-width: 100%;
    filter: grayscale(100%) sepia(90%) hue-rotate(60deg) saturate(300%)
        contrast(0.7);
    transition: all 0.5s ease-in-out;
}
.profilePicture img:hover {
    border-radius: 50%;
    border-bottom-right-radius: 0;
    width: 500px;
    max-width: 100%;
    filter: none;
}
.profilePicture {
    width: 30%;
    max-width: 30rem;
    min-width: 10rem;
}
.aboutMe {
    display: inline-block;
    font-size: 2.5rem;
    width: 30%;
    color: #fff;
    padding: 2%;
    min-width: 300px;
    text-align: left;
    vertical-align: middle;
}
.aboutMe span:not([role='img']) {
    display: block;
    font-size: 1.5rem;
}
.aboutMe p {
    margin: 0;
    font-size: 1.34rem;
    line-height: 1.4em;
}
.emailSvg {
    max-width: 90%;
    cursor: pointer;
}

@media (max-width: 500px) {
    .sideBySide {
        display: block;
        padding: 2em;
        padding-bottom: 5em;
    }
    .sideBySide h1 {
        font-size: 1em;
    }
    .aboutMe p {
        margin: 0;
        font-size: 1rem;
        line-height: 1.2em;
        max-width: 80%
    }
}
