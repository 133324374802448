.paddingWrapper {
    padding: 1em;
    white-space: normal;
    height: calc(100% - 15em);
}
.sideBySide {
    height: 100%;
    display: flex;
}
.sideBySide > div {
    padding: 0.5em;
}
.sideBySide > div:first-child {
    border-right: 1px solid #90ee9033;
}
@media (max-width: 500px) {
    .sideBySide > div {
        padding: 0;
    }
}
.sideBySide :nth-child(2) {
    flex: 1;
}
@media (max-width: 500px) {
    .sideBySide {
        display: block;
        padding: 2em;
        padding-bottom: 5em;
    }
    .sideBySide h1 {
        font-size: 1em;
    }
}
.title {
    font-size: 1.5rem;
    color: #fff;
}
.paddedList {
    padding: 1%;
}
.theRightSideList {
    font-size: 1.5rem;
    line-height: 2rem;
    color: #fff;
    overflow-y: scroll;
    max-height: 100%;
    padding-right: 15px;
}

.theRightSideList::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #333;
}

.theRightSideList::-webkit-scrollbar {
    width: 12px;
    background-color: #333;
    border-radius: 10px;
}

.theRightSideList::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #333;
}

.theRightSideList:hover::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #333;
}

.theRightSideList:hover::-webkit-scrollbar {
    width: 12px;
    background-color: #333;
    border-radius: 10px;
    visibility: visible;
}

.theRightSideList:hover::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #605a77;
    visibility: visible;
}

.theRightSideList:hover {
    max-width: 100%;
    width: 100%;
}
@media (max-width: 500px) {
    .theRightSideList {
        overflow-y: scroll;
        max-height: 100%;
        width: 100%;
    }
}
.theRightSideList a {
    color: lightgreen;
}
.theRightSideList li {
    margin: 20px;
}
.badge {
    display: inline-block;
    padding: 0 0.3em;
    background: lightgreen;
    color: #333;
    font-size: 0.5em;
    height: 24px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-radius: 3px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 2em;
}
.justGreen {
    color: lightgreen;
}
.websitesIbuilt {
    margin-top: 2%;
}
