.page {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #383448;
    margin: 0 1rem;
    vertical-align: top;
}

.page:first-child {
    margin-left: 0;
}

.page:last-of-type {
    margin-right: 0;
}
