.carousel {
    width: 100%;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    min-height: 500px;
}
.slidingChild {
    white-space: nowrap;
    height: 100%;
    transform: translateX(0);
    transition: transform 1s ease-in-out 0.5s;
    will-change: transform;
}
.carousel[data-transitioning='true'] {
    animation: shrinkAndBack 1.8s ease-in-out forwards;
}
@keyframes shrinkAndBack {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(0.98);
    }
    80% {
        transform: scale(0.98);
    }
    100% {
        transform: scale(1);
    }
}
